<div class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="900px">
            <mat-card>
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div>
                        <mat-card-header>
                            <mat-card-title class="card-title-container">
                                <span *ngIf="event !== null; else no_event_container">
                                    <span [innerHTML]="event.name">
                                    </span>
                                </span>
                                <ng-template #no_event_container>
                                    No Process Selected
                                </ng-template>
                            </mat-card-title>
                            <mat-card-subtitle>
                                <span *ngIf="event !== null">
                                    B = {{bFieldStrength}} kG ({{bFieldDirection}})
                                </span>
                            </mat-card-subtitle>
                        </mat-card-header>
                    </div>
                    <div>
                        <button *ngIf="revealEvent" mat-stroked-button (click)="hideEvent()" color="primary" class="show-hide-button">Hide Event</button>
                        <button *ngIf="!revealEvent" mat-stroked-button (click)="showEvent()" color="primary" class="show-hide-button">Show Event</button>
                        <button mat-stroked-button (click)="closeAnalysisDisplay()" color="primary">Close</button>
                    </div>
                </div>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-around start">
                        <div fxFlex="55%">
                            <div fxLayout="row" fxLayoutAlign="space-around center">
                                <div *ngIf="boundaries && dots">
                                    <svg [attr.height]="boundaries.height" [attr.width]="boundaries.width">
                                        <rect [attr.x]="boundaries.xminPx" [attr.y]="boundaries.ymaxPx"
                                            [attr.width]="boundaries.widthDisplay" [attr.height]="boundaries.heightDisplay"
                                            style="fill:blue;stroke:grey;stroke-width:5;fill-opacity:0.1;stroke-opacity:0.9" />
                
                                        <g *ngIf="eventDisplay && revealEvent">
                                            <path *ngIf="eventDisplay" [attr.d]="eventDisplay.inCharged" stroke="olivedrab"
                                                stroke-width="4" fill="none">
                                            </path>
                                            <path *ngIf="eventDisplay" [attr.d]="eventDisplay.inNeutral" stroke="olivedrab"
                                                stroke-dasharray="10, 5" stroke-width="4">
                                            </path>
                                            <path *ngIf="eventDisplay" [attr.d]="eventDisplay.outCharged" stroke="sandybrown"
                                                stroke-width="4" fill="none">
                                            </path>
                                            <path *ngIf="eventDisplay" [attr.d]="eventDisplay.outNeutral" stroke="sandybrown"
                                                stroke-dasharray="10, 5" stroke-width="4">
                                            </path>
                                        </g>
                
                                        <g *ngFor="let circle of circles">
                                            <circle [attr.r]="circle.rPx" [attr.cx]="circle.xcPx" [attr.cy]="circle.ycPx"
                                                [attr.stroke-width]="circle.strokeWidth()" stroke-dasharray="3, 7, 10, 7"
                                                [attr.stroke]="circle.circleColour()" fill="none">
                                            </circle>
                                        </g>
                
                                        <g *ngFor="let dot of dots">
                                            <circle (mouseover)="dot.checkDot(false)" [attr.r]="dot.findRadius()"
                                                [attr.cx]="dot.x" [attr.cy]="dot.y" [attr.stroke-width]="dot.findStrokeWidth()"
                                                [attr.stroke]="dot.findStrokeColour()" [attr.fill]="dot.findFillColour()">
                                            </circle>
                                        </g>
                
                                        <g *ngIf="eventDisplay && showAxes">
                                            <line [attr.x1]="hAxisParams.x1" [attr.x2]="hAxisParams.x2" [attr.y1]="hAxisParams.y1"
                                                [attr.y2]="hAxisParams.y2" [attr.stroke-width]="1" [attr.stroke]="'grey'">
                                            </line>
                                            <line [attr.x1]="vAxisParams.x1" [attr.x2]="vAxisParams.x2" [attr.y1]="vAxisParams.y1"
                                                [attr.y2]="vAxisParams.y2" [attr.stroke-width]="1" [attr.stroke]="'grey'">
                                            </line>
                                        </g>
                                    </svg>
                
                                </div>
                                <div *ngIf="!(boundaries && dots)">
                                    Loading....
                                </div>
                            </div>
                        </div>
                        <div fxFlex="40%">
                            <div *ngIf="momentumDiagramBoundaries && eventDisplay && showAxes">
                                <div fxLayout="row" fxLayoutAlign="space-around center">
                                    <app-momentum-axis [circles]="circles" [boundaries]="momentumDiagramBoundaries"
                                        [wakeUp]="circleChange">
                                    </app-momentum-axis>
                                </div>
                            </div>
                            <app-circle-table [circles]="circles" [event]="event" [userIsReadOnly]="'true'"
                                [interactionLocation]="interactionLocation" (circleChanged)="onChangedCircles()"
                                (highlightDots)="highlightFitDots($event)" (unhighlightDots)="unhighlightFitDots($event)">
                            </app-circle-table>
                        </div>
                
                    </div>
                
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>