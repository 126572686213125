<div *ngIf="errorMessage" class="error-message">
    {{errorMessage}}
</div>
<div fxLayout="row" fxLayoutAlign="space-around start" [ngClass]="{'extra-left-right-padding': isPublicUser}">
    <div fxFlex="55%">
        <mat-card-header>
            <mat-card-title class="card-title-container">
                <span *ngIf="!noEventRetrieved && event !== null; else no_event_container" #titleTooltip="matTooltip"
                    matTooltip="X and Y refer to unknown particles whose identities you will need to determine in your offline analysis.  Possibilities may be found in the List of Particle Decays in the side navigation menu."
                    matTooltipPosition="right">
                    <span [innerHTML]="event.name">
                    </span>
                </span>
                <ng-template #no_event_container>
                    <span *ngIf="noEventRetrieved">
                        No Process Selected
                    </span>
                </ng-template>
            </mat-card-title>
            <mat-card-subtitle>
                <span *ngIf="!noEventRetrieved" #subtitleTooltip="matTooltip"
                    matTooltip="The magnetic field strength and direction (into or out of the page)"
                    matTooltipPosition="below">
                    B = {{bFieldStrength}} kG ({{bFieldDirection}})
                </span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="end">
                <span *ngIf="editModeOn" class="negative-top-margin" #selectDeselectSliderTooltip="matTooltip"
                    matTooltip="Deselect accidentally selected points -- slide back to select points again"
                    matTooltipPosition="below">
                    <mat-label class="mat-slide-toggle-content">
                        Deselect&nbsp;
                    </mat-label>
                    <mat-slide-toggle color="primary" [checked]="colourModeOn" (change)="toggleColourMode()">
                    </mat-slide-toggle>
                    <mat-label class="mat-slide-toggle-content">&nbsp;Select
                    </mat-label>
                </span>
            </div>
            <div *ngIf="boundaries && dots">
                <svg [attr.height]="boundaries.height" [attr.width]="boundaries.width">
                    <rect [attr.x]="boundaries.xminPx" [attr.y]="boundaries.ymaxPx"
                        [attr.width]="boundaries.widthDisplay" [attr.height]="boundaries.heightDisplay"
                        style="fill:blue;stroke:grey;stroke-width:5;fill-opacity:0.1;stroke-opacity:0.9" />

                    <g *ngIf="eventDisplay && ((!editModeOn)||revealEvent)">
                        <path *ngIf="eventDisplay" [attr.d]="eventDisplay.inCharged" stroke="olivedrab" stroke-width="4"
                            fill="none">
                        </path>
                        <path *ngIf="eventDisplay" [attr.d]="eventDisplay.inNeutral" stroke="olivedrab"
                            stroke-dasharray="10, 5" stroke-width="4">
                        </path>
                        <path *ngIf="eventDisplay" [attr.d]="eventDisplay.outCharged" stroke="sandybrown"
                            stroke-width="4" fill="none">
                        </path>
                        <path *ngIf="eventDisplay" [attr.d]="eventDisplay.outNeutral" stroke="sandybrown"
                            stroke-dasharray="10, 5" stroke-width="4">
                        </path>
                    </g>

                    <g *ngFor="let circle of circles">
                        <circle [attr.r]="circle.rPx" [attr.cx]="circle.xcPx" [attr.cy]="circle.ycPx"
                            [attr.stroke-width]="circle.strokeWidth()" stroke-dasharray="3, 7, 10, 7"
                            [attr.stroke]="circle.circleColour()" fill="none">
                        </circle>
                    </g>

                    <g *ngFor="let dot of dots">
                        <circle (mouseover)="dot.checkDot(colourModeOn)" [attr.r]="dot.findRadius()" [attr.cx]="dot.x"
                            [attr.cy]="dot.y" [attr.stroke-width]="dot.findStrokeWidth()"
                            [attr.stroke]="dot.findStrokeColour()" [attr.fill]="dot.findFillColour()">
                        </circle>
                    </g>

                    <g *ngIf="eventDisplay && editModeOn && showAxes">
                        <line [attr.x1]="hAxisParams.x1" [attr.x2]="hAxisParams.x2" [attr.y1]="hAxisParams.y1"
                            [attr.y2]="hAxisParams.y2" [attr.stroke-width]="1" [attr.stroke]="'grey'">
                        </line>
                        <line [attr.x1]="vAxisParams.x1" [attr.x2]="vAxisParams.x2" [attr.y1]="vAxisParams.y1"
                            [attr.y2]="vAxisParams.y2" [attr.stroke-width]="1" [attr.stroke]="'grey'">
                        </line>
                    </g>

                </svg>

            </div>

            <div *ngIf="!userIsReadOnly">
                <div *ngIf="!editModeOn">
                    <button mat-stroked-button color="primary" (click)="fetchNewEvent()" #getEventTooltip="matTooltip"
                        matTooltip="First step of the process -- get an event to analyze" matTooltipPosition="above">Get
                        Event</button>
                    <button *ngIf="event" mat-stroked-button color="primary" (click)="turnOnEditMode()"
                        class="button-space-left" #analyzeEventTooltip="matTooltip"
                        matTooltip="Choose this event to analyze" matTooltipPosition="below">Analyze
                        This
                        Event</button>
                </div>
                <div *ngIf="editModeOn">
                    <button *ngIf="!userIsReadOnly" mat-stroked-button color="primary" (click)="addCircle()"
                        #addCircleTooltip="matTooltip"
                        matTooltip="Hover to select points for one of the circular paths and then click to fit a circle to the points"
                        matTooltipPosition="above">Add
                        Circle</button>
                    <button mat-stroked-button color="primary" [disabled]="circles.length === 0"
                        [matMenuTriggerFor]="menu" class="button-space-left" #deleteCircleTooltip="matTooltip"
                        matTooltip="Delete one or more circles" matTooltipPosition="below">Delete Circle</button>
                    <mat-menu #menu="matMenu">
                        <div *ngFor="let circle of circles; let i=index">
                            <button mat-menu-item (mouseenter)="highlight(circle)" (mouseout)="unhighlight(circle)"
                                (click)="deleteCircle(i)">
                                Circle #{{i+1}}
                            </button>
                        </div>
                    </mat-menu>
                    <button *ngIf="!userIsReadOnly && !revealEvent" mat-stroked-button color="primary"
                        (click)="showEvent()" class="button-space-left" #showEventTooltip="matTooltip"
                        matTooltip="Show the actual paths taken by the particles" matTooltipPosition="right">
                        Show Event
                    </button>
                    <button *ngIf="!userIsReadOnly && revealEvent" mat-stroked-button color="primary"
                        (click)="hideEvent()" class="button-space-left" #hideEventTooltip="matTooltip"
                        matTooltip="Hide the particles' paths" matTooltipPosition="right">
                        Hide Event
                    </button>

                </div>
                <!--<div *ngIf="revealedEvent && isAdmin()">
                    <strong>Event: </strong>
                    <span [innerHTML]="revealedEvent"></span>
                </div>-->
                <div *ngIf="eventsSameSignature.length > 0" class="extra-top-margin">
                    <strong>Events matching this signature: </strong>
                    <div *ngFor="let eventSameSignature of eventsSameSignature" class="event-body-small-bottom-margin"
                        [innerHTML]="eventSameSignature"></div>
                </div>
                <div>
                    <button *ngIf="editModeOn && isPublicUser" mat-stroked-button color="primary"
                        (click)="onShowReviewCard()" #showReviewCardTooltip="matTooltip"
                        matTooltip="Review analysis of this event" matTooltipPosition="above">
                        <span *ngIf="!reviewCardShown">Review</span>
                        <span *ngIf="reviewCardShown">Hide Review</span>
                    </button>
                </div>
            </div>
        </mat-card-content>

    </div>
    <div fxFlex="40%">
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="end start">
                <!-- https://www.angularjswiki.com/angular/how-to-add-a-class-based-on-condition-in-angular/ -->
                <button *ngIf="editModeOn" mat-stroked-button color="primary" (click)="clearSnackBarsAndInitialize()"
                    [ngClass]="{'margin-below-button': !(momentumDiagramBoundaries && eventDisplay && showAxes)}"
                    #getNewEventTooltip="matTooltip" matTooltip="Discard the current event and start over"
                    matTooltipPosition="above">Get
                    New Event</button>
                <button mat-stroked-button color="primary" (click)="openHelpDialog()"
                    class="button-space-left">Help</button>
                <button mat-stroked-button color="primary" (click)="showTooltips()" class="button-space-left">Show
                    Hints</button>
            </div>
            <div *ngIf="momentumDiagramBoundaries && eventDisplay && editModeOn && showAxes">
                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <app-momentum-axis [circles]="circles" [boundaries]="momentumDiagramBoundaries"
                        [wakeUp]="circleChange">
                    </app-momentum-axis>
                </div>
            </div>
            <app-circle-table [circles]="circles" [event]="event" [userIsReadOnly]="userIsReadOnly"
                [interactionLocation]="interactionLocation" (circleChanged)="onChangedCircles()"
                (highlightDots)="highlightFitDots($event)" (unhighlightDots)="unhighlightFitDots($event)">
            </app-circle-table>
        </mat-card-content>
    </div>

</div>

<div *ngIf="isPublicUser" fxLayout="row" fxLayoutAlign="space-around center"
    class="card-top-space">
    <div fxFlex.gt-sm="650px">
        <mat-card class="warning-card">
            <mat-card-content>
                <mat-icon class="align-icon">warning</mat-icon>&nbsp; This is a preview version only.  If you wish to save or submit your analyzed event, please <a [routerLink]="['/login']">sign in</a>.
            </mat-card-content>
        </mat-card>
    </div>
</div>