<mat-accordion>
    <mat-expansion-panel *ngFor="let circle of circles; let i=index" (mouseenter)="highlight(circle)"
        (mouseleave)="unhighlight(circle)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Circle #{{i+1}}
            </mat-panel-title>
            <mat-panel-description>
                Edit circle properties
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutAlign="start">
            <div fxFlex="30%">
                r:
            </div>
            <div fxFlex="70%">
                {{circle.r | roundReal : 4}} cm
            </div>
        </div>
        <div *ngIf="circle.theta">
            <div fxLayout="row wrap" fxLayoutAlign="start">
                <div fxFlex="30%">
                    &theta;:
                </div>
                <div fxFlex="70%">
                    {{circle.theta | roundReal : 4}} rad
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="30%">
                rotn:
            </div>
            <div fxFlex="70%">
                <!--https://stackoverflow.com/questions/58204128/how-to-label-both-sides-of-a-mat-slide-toggle-control-in-angular-material-8-->
                <mat-label class="mat-slide-toggle-content">
                    <app-ccw-icon></app-ccw-icon>&nbsp;
                </mat-label>
                <mat-slide-toggle color="primary" [disabled]="userIsReadOnly" [checked]="circle.CW" (change)="resetRotationDirection(circle)"></mat-slide-toggle>
                <mat-label class="mat-slide-toggle-content">&nbsp;<app-cw-icon></app-cw-icon>
                </mat-label>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="30%">
                direction:
            </div>
            <div fxFlex="70%">
                <mat-label class="mat-slide-toggle-content orange-text">
                    outgoing&nbsp;
                </mat-label>
                <mat-slide-toggle color="primary" [disabled]="userIsReadOnly" [checked]="circle.incoming" (change)="resetParticleDirection(circle)">
                </mat-slide-toggle>
                <mat-label class="mat-slide-toggle-content green-text">&nbsp;incoming</mat-label>
            </div>
        </div>
    </mat-expansion-panel>

</mat-accordion>