<div class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="900px">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Users for {{institutionName}}
                    </mat-card-title>
                    <mat-card-subtitle>
                        Click title bar to sort by activity date, email, name, etc.
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="errorMessage" class="error-message">
                        {{errorMessage}}
                    </div>
                    <div class="mat-elevation-z1">
                        <div class="search-box">
                            <mat-form-field>
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="smith">
                            </mat-form-field>
                            <mat-icon class="icon-align">search</mat-icon>
                        </div>
                        <!--https://stackoverflow.com/questions/46743115/default-sorting-in-angular-material-sort-header-->
                        <table mat-table [dataSource]="dataSource" matSort matSortActive="latest_activity" matSortDirection="desc">
                            <!-- First name column -->
                            <ng-container matColumnDef="firstName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="first_name"> First Name </th>
                                <td mat-cell *matCellDef="let element">{{element.first_name}}</td>
                            </ng-container>
                            <!-- Last name column -->
                            <ng-container matColumnDef="lastName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="last_name"> Last Name </th>
                                <td mat-cell *matCellDef="let element">{{element.last_name}}</td>
                            </ng-container>
                            <!-- Email column -->
                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </th>
                                <td mat-cell *matCellDef="let element">{{element.email}}</td>
                            </ng-container>
                            <!-- Username column -->
                            <ng-container matColumnDef="username">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="username"> Username </th>
                                <td mat-cell *matCellDef="let element">{{element.username}}</td>
                            </ng-container>
                            <!-- Institution column -->
                            <!--
                            <ng-container matColumnDef="institution">
                                <th mat-header-cell *matHeaderCellDef> Institution </th>
                                <td mat-cell *matCellDef="let element">{{element.institution_name}}</td>
                            </ng-container>
                        -->
                            <!-- # of events column -->
                            <ng-container matColumnDef="numberEvents">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="number_events"> # of Events </th>
                                <td mat-cell *matCellDef="let element" class="centered-content">
                                    {{element.number_events}}</td>
                            </ng-container>
                            <!-- # of events column -->
                            <ng-container matColumnDef="latestActivity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="latest_activity"> Most Recent Activity</th>
                                <td mat-cell *matCellDef="let element">
                                    {{moment(element.latest_activity).fromNow()}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> Actions </th>
                                <td mat-cell *matCellDef="let element" class="centered-content">
                                    <mat-icon *ngIf="element.number_events > 0" class="action-icon"
                                        matTooltip="View user's events"
                                        [routerLink]="['/teacher/user-events', element.id]">pageview</mat-icon>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>