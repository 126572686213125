<div class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="900px">
            <mat-card>
                <mat-card-header>
                    <mat-card-title class="help-title-container">
                        Analysis -- Part I (Online)
                    </mat-card-title>
                    <mat-card-subtitle class="help-title-container">
                        Some help getting started with the online portion of the analysis.  
                        <span *ngIf="isLoggedIn()"> 
                            Click <a [routerLink]="['/events']">here</a> if you're ready to analyze an event.
                        </span>
                        <span *ngIf="!isLoggedIn()">
                            <a [routerLink]="['/login']">Sign in</a> if you're ready to analyze an event.
                        </span>
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div class="help-body-container">
                        <app-help-online-analysis [includeLinks]="includeLinks"></app-help-online-analysis>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>


