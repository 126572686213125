<div class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="900px">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Particle Properties
                    </mat-card-title>
                    <mat-card-subtitle>
                        List of properties for particles that could be encountered in Particle Tracks.
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">
                            <ng-container matColumnDef="symbol">
                                <th mat-header-cell *matHeaderCellDef> Symbol </th>
                                <td mat-cell *matCellDef="let element">
                                    <span [innerHtml]="element.symbol"></span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.humanReadableName}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="charge">
                                <th mat-header-cell *matHeaderCellDef> Charge </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.charge}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mass">
                                <th mat-header-cell *matHeaderCellDef> Mass (MeV) </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.mass}}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    
                        <h5 class="no-bottom-margin"><sup>*</sup>We have simplified some notation here. In particular, there is more than one type of neutrino.
                        Also, note that the symbol for the photon is the Greek letter gamma (&#947;), which looks a bit like a "Y" in some fonts, but is not one!</h5>
                    </div>


                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>