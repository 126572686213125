<div class="container">
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0px" fxLayoutGap.xs="0px">
            <mat-card class="reset-password-card">
                <h1>Reset Password</h1>
                <!--<mat-card-title class="card-title">Login</mat-card-title>-->
                <mat-card-content>
                    <mat-error class="reset-password-error-message">
                        {{resetPasswordServerError}}
                    </mat-error>
                    <form [formGroup]="resetPasswordForm" novalidate (ngSubmit)="onSubmit()">
                        <mat-form-field appearance="outline" class="input-formfield">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="user@example.com" type="email" formControlName="email"
                                required>
                            <mat-error *ngIf="resetPasswordForm.get('email').invalid">{{getEmailErrorMessage()}}
                            </mat-error>
                        </mat-form-field>
                        <p>Please enter the email address for your account.</p>
                        <!--<mat-divider></mat-divider>-->
                        <!-- https://stackoverflow.com/questions/32062051/how-to-make-submit-button-disabled -->
                        <button mat-button
                            [disabled]="resetPasswordForm.invalid && (resetPasswordForm.touched || resetPasswordForm.dirty)">Submit</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
            <button [routerLink]="['/login']" mat-stroked-button mat-large class="extra-top-margin" color="primary">Sign In</button>
        </div>
    </div>
</div>