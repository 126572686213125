<div class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="900px">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Analyzed Events
                    </mat-card-title>
                    <mat-card-subtitle>
                        Click title bar to sort by date or submission status
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="errorMessage" class="error-message">
                        {{errorMessage}}
                    </div>
                    <div class="mat-elevation-z1">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <!-- Event column -->
                            <ng-container matColumnDef="title">
                                <th mat-header-cell *matHeaderCellDef> Event </th>
                                <td mat-cell *matCellDef="let element" [innerHTML]="element.title"></td>
                            </ng-container>
                            <!-- Time stamp columns -->
                            <!-- https://devhints.io/moment -->
                            <ng-container matColumnDef="created">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
                                <td mat-cell *matCellDef="let element"> {{moment(element.created).format('LLL')}}</td>
                            </ng-container>
                            <ng-container matColumnDef="updated">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
                                <td mat-cell *matCellDef="let element"> {{moment(element.updated).format('LLL')}} -
                                    {{moment(element.updated).fromNow()}}</td>
                            </ng-container>
                            <!-- Submitted column -->
                            <ng-container matColumnDef="submitted">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-content"> Submitted? </th>
                                <td mat-cell *matCellDef="let element" class="centered-content">
                                    <span *ngIf="element.submitted">
                                        <mat-icon class="check-icon">check_circle</mat-icon>
                                    </span>
                                </td>
                            </ng-container>
                            <!-- Actions column -->
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="centered-content"> Actions </th>
                                <td mat-cell *matCellDef="let element" class="centered-content">
                                    <mat-icon class="action-icon" matTooltip="Edit this event (submit, unsubmit, etc.)" (click)="editEvent(element)">edit</mat-icon>
                                    <mat-icon class="action-icon" matTooltip="Delete this event" (click)="openDialog(element)">delete</mat-icon>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>