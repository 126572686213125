<h2 *ngIf="!data.editModeOn && !data.event" mat-dialog-title>Getting Started</h2>
<h2 *ngIf="!data.editModeOn && data.event" mat-dialog-title>Events</h2>
<h2 *ngIf="data.editModeOn && data.event" mat-dialog-title>Analysis (Online Portion)</h2>

<mat-dialog-content class="mat-typography">

    <!-- Event not yet selected or -->

    <div *ngIf="!data.editModeOn && !data.event">
        <h3>First Step</h3>

        <p>
            The first step of your analysis is to choose an event.
            Click on <strong>Get Event</strong> until you see one
            that you like and then click <strong>Analyze This Event</strong>
            to select it for analysis.
        </p>

    </div>

    <div *ngIf="!data.editModeOn && data.event">

        <!--<h3 [innerHTML]="'Your Event: '+data.event.name"></h3>-->

        <p>
            A particle physics decay <strong>event</strong> is when an unstable particle
            decays spontaneously into two or more other particles.
        </p>

        <p>
            For example, for the decay <span [innerHTML]="data.event.name"></span>, the incoming particle
            <span *ngIf="!data.event.parent.particle_alias" [innerHTML]="data.event.parent.particle_name"></span>
            <span *ngIf="data.event.parent.particle_alias" [innerHTML]="data.event.parent.particle_alias"></span>
            decays to the outgoing particles
            <span *ngFor="let dp of data.event.decay_products; let i=index">
                <span *ngIf="i>0 && data.event.decay_products.length > 2">, </span>
                <span *ngIf="i === data.event.decay_products.length -1"> and </span>
                <span *ngIf="!dp.particle_alias" [innerHTML]="dp.particle_name"></span>
                <span *ngIf="dp.particle_alias" [innerHTML]="dp.particle_alias"></span>
            </span>.
        </p>

        <p>
            Any particles denoted by an X or Y in this simulation are unknown. The eventual goal of your analysis will
            be
            to <strong>determine the identities</strong> of these particles.
        </p>
    </div>

    <div *ngIf="data.editModeOn && data.event">

        <h3 [innerHTML]="'Your Event: '+data.event.name"></h3>

        <p>
            For this decay event, the incoming particle
            <span *ngIf="!data.event.parent.particle_alias" [innerHTML]="data.event.parent.particle_name"></span>
            <span *ngIf="data.event.parent.particle_alias" [innerHTML]="data.event.parent.particle_alias"></span>
            decays to the outgoing particles
            <span *ngFor="let dp of data.event.decay_products; let i=index">
                <span *ngIf="i>0 && data.event.decay_products.length > 2">, </span>
                <span *ngIf="i === data.event.decay_products.length -1"> and </span>
                <span *ngIf="!dp.particle_alias" [innerHTML]="dp.particle_name"></span>
                <span *ngIf="dp.particle_alias" [innerHTML]="dp.particle_alias"></span>
            </span>. Any particles denoted by an X or Y in this simulation are unknowns.
            The side navigation menu contains a list of all
            the decays that could appear in this simulation. Comparison with your decay shows that <strong>your decay
                could be one of the following</strong>:
        </p>

        <div *ngIf="data.eventsSameSignature.length > 0" class="event-body">
            <!--<div class="help-dialog-extra-bottom-margin">
                Events matching this signature:
            </div>-->
            <div *ngFor="let eventSameSignature of data.eventsSameSignature" [innerHTML]="eventSameSignature"></div>
        </div>

        <p class="extra-margin-bottom-above-divider">
            One of the goals of your
            analysis will be
            to determine which of the above is the correct decay process for your event.
        </p>

        <mat-divider></mat-divider>

        <app-help-online-analysis></app-help-online-analysis>

    </div>




</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="onClose()">OK</button>
</mat-dialog-actions>