import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ccw-icon',
  templateUrl: './ccw-icon.component.svg',
  styleUrls: ['./ccw-icon.component.scss']
})
export class CcwIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
