<div *ngIf="resetPasswordForm" class="container">
    <div fxLayout="column">

        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0px" fxLayoutGap.xs="0px">

            <!-- https://developer.okta.com/blog/2020/01/21/angular-material-login ...to be continued.....-->

            <mat-card class="reset-card">
                <h1>Choose a New Password</h1>
                <mat-card-content>

                    <mat-error *ngIf="!showResetPasswordButton" class="reset-error-message">
                        {{resetPasswordServerError}}
                    </mat-error>

                    <mat-error *ngIf="showResetPasswordButton" class="reset-error-message">
                        Sorry, your password could not be reset.  If you'd like, you can <a [routerLink]="['/reset-password']">request a new reset-password link</a>.
                    </mat-error>

                    <form [formGroup]="resetPasswordForm" novalidate (ngSubmit)="onSubmit()">
                        <div formGroupName="passwords">
                            <mat-form-field type="password" appearance="outline" class="input-formfield">
                                <mat-label>Password</mat-label>
                                <input matInput placeholder="Password" type="password" formControlName="password"
                                    required>
                                <mat-error>
                                    This field is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field type="password" appearance="outline" class="input-formfield">
                                <mat-label>Reenter Password</mat-label>
                                <input matInput placeholder="Password" type="password" formControlName="password2"
                                    required>
                                <mat-error>
                                    This field is required
                                </mat-error>
                                <!--<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
                                <!--<mat-hint>Hint</mat-hint>-->
                            </mat-form-field>
                            <!--https://stackoverflow.com/questions/46926182/property-controls-does-not-exist-on-type-abstractcontrol-angular-4-->
                            <mat-error
                                *ngIf="resetPasswordForm.get('passwords').errors && resetPasswordForm.get('passwords')['controls'].password.touched && resetPasswordForm.get('passwords')['controls'].password2.touched"
                                class="error-bottom-margin">
                                {{getPasswordErrorMessage()}}
                            </mat-error>
                        </div>
                
                        <!-- https://stackoverflow.com/questions/32062051/how-to-make-submit-button-disabled -->
                        <button mat-button
                            [disabled]="resetPasswordForm.invalid && (resetPasswordForm.touched || resetPasswordForm.dirty)">Submit</button>
                    </form>
                </mat-card-content>
            </mat-card>

        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="extra-top-margin">
            <button [routerLink]="['/login']" mat-stroked-button mat-large color="primary">Sign In</button>
            <button *ngIf="showResetPasswordButton" [routerLink]="['/reset-password']" mat-stroked-button mat-large color="primary" class="extra-left-margin">Request Reset Password Link</button>
        </div>
    </div>
</div>