<div class="table-container">
    <h3>Decays involving one neutral particle<sup>*</sup></h3>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="decay">
            <th mat-header-cell *matHeaderCellDef> Decay </th>
            <td mat-cell *matCellDef="let element">
                <particle-tt [particleName]="element.parent"></particle-tt>
                &rarr;
                <span *ngFor="let child of element.children; let i = index">
                    <span *ngIf="i>0"> + </span>
                    <particle-tt [particleName]="child"></particle-tt>
                </span>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="relatedDecay">
            <th mat-header-cell *matHeaderCellDef> Related Decay<sup>&#8224;</sup> </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.antiParent">
                    <particle-tt [particleName]="element.antiParent"></particle-tt>
                    &rarr;
                    <span *ngFor="let antiChild of element.antiChildren; let i = index">
                        <span *ngIf="i>0"> + </span>
                        <particle-tt [particleName]="antiChild"></particle-tt>
                    </span>
                </div>
                <div *ngIf="element.relatedDecayText">{{element.relatedDecayText}}</div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <h5 class="no-bottom-margin"><sup>*</sup>We have simplified some notation here. In particular, there is more than one type of neutrino.
        Also, note that the symbol for the photon is the Greek letter gamma (&#947;), which looks a bit like a "Y" in some fonts, but is not one!</h5>
    <h5><sup>&#8224;</sup>The "related decays" here have the particles
        and antiparticles switched.
    </h5>
</div>