<div class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="900px">
            <mat-card>
                <mat-vertical-stepper linear #stepper>
                    <mat-step>
                        <!--<form [formGroup]="firstFormGroup">-->
                        <ng-template matStepLabel>Choose and/or Analyze Event</ng-template>

                        <app-analysis-display
                            (analysisStatus)="onAnalysisStatusUpdate($event)">
                        </app-analysis-display>



                    </mat-step>
                    <mat-step>
                        <!--<form [formGroup]="secondFormGroup">-->
                        <ng-template matStepLabel>Review</ng-template>

                        <app-review-event
                            (reviewStatus)="onReviewStatusUpdate($event)">
                        </app-review-event>

                        <!--
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                        -->
                        <!--</form>-->
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Finish</ng-template>
                        
                        <div *ngIf="canSubmit && (!errorsExist)" fxLayout="row" fxLayoutAlign="space-around center" class="card-top-space">
                            <div fxFlex.gt-sm="650px">
                                <mat-card class="ready-to-submit-card">
                                    <mat-card-content>
                                        <mat-icon class="align-icon">check_circle_outline</mat-icon>&nbsp; 
                                        The analysis of your event has passed all basic checks.  If you're happy with your analysis
                                        please click on the Submit button below.
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>

                        <div *ngIf="canSubmit && errorsExist" fxLayout="row" fxLayoutAlign="space-around center" class="card-top-space">
                            <div fxFlex.gt-sm="650px">
                                <mat-card class="warning-card">
                                    <mat-card-content>
                                        <mat-icon class="align-icon">warning</mat-icon>&nbsp;
                                        Your analysis appears to contain one or more errors (see the Review tab for details).  
                                        You can still Submit your event if you like, but it is recommended that you first correct the issues.
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>

                        <div *ngIf="!canSubmit" fxLayout="row" fxLayoutAlign="space-around center" class="card-top-space">
                            <div fxFlex.gt-sm="650px">
                                <mat-card class="warning-card">
                                    <mat-card-content>
                                        <mat-icon class="align-icon">send</mat-icon>&nbsp;
                                        Your analysis appears to contain one or more errors that are preventing submission
                                        of the event.  See the Review step for details.
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>

                        <div>
                            <button mat-button (click)="submitEvent()" [disabled]="!canSubmit" class="button-top-margin">Submit</button>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </mat-card>
        </div>
    </div>
</div>