<div class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex.gt-sm="900px">
            <mat-card>
                <mat-card-header>
                    <mat-card-title class="help-title-container">
                        Analysis -- Part II (Offline)
                    </mat-card-title>
                    <mat-card-subtitle class="help-title-container">
                        Some help getting started with the offline portion of the analysis.  If you haven't already done so, 
                        you may wish to start by reading about the <a [routerLink]="['/public/help-online-analysis']">online</a> 
                        part of the analysis.
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div class="help-body-container">
                        <app-help-offline-analysis></app-help-offline-analysis>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>