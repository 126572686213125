<div *ngIf="profileForm" class="container">
    <div fxLayout="column">

        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0px" fxLayoutGap.xs="0px">

        <!-- https://developer.okta.com/blog/2020/01/21/angular-material-login ...to be continued.....-->

        <mat-card class="profile-card">
            <h1>Update Account</h1>
            <!--<mat-card-title class="card-title">Login</mat-card-title>-->
            <mat-card-content>

                <mat-error class="update-error-message">
                    {{updateServerError}}
                </mat-error>

                <form [formGroup]="profileForm" novalidate (ngSubmit)="onSubmit()">
                    <mat-form-field appearance="outline" class="input-formfield">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First Name" type="text" formControlName="firstName" required>
                        <mat-icon *ngIf="!editFirstName" (click)="enableField('firstName')" class="include-pointer" matSuffix>edit</mat-icon>
                        <mat-error>
                            This field is required
                        </mat-error>
                        <!--<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
                        <!--<mat-hint>Hint</mat-hint>-->
                    </mat-form-field>
                    
                
                    <mat-form-field appearance="outline" class="input-formfield">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last Name" type="text" formControlName="lastName" required>
                        <mat-icon *ngIf="!editLastName" (click)="enableField('lastName')" class="include-pointer" matSuffix>edit</mat-icon>
                        <mat-error>
                            This field is required
                        </mat-error>
                        <!--<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
                        <!--<mat-hint>Hint</mat-hint>-->
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="input-formfield">
                        <mat-label>Username</mat-label>
                        <input matInput placeholder="Username" type="text" formControlName="username" required>
                        <mat-icon *ngIf="!editUsername" (click)="enableField('username')" class="include-pointer" matSuffix>edit</mat-icon>
                        <mat-error>
                            This field is required
                        </mat-error>
                        <!--<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
                        <!--<mat-hint>Hint</mat-hint>-->
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="input-formfield">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="user@example.com" type="email" formControlName="email" required>
                        <mat-icon *ngIf="!editEmail" (click)="enableField('email')" class="include-pointer" matSuffix>edit</mat-icon>
                        <mat-error *ngIf="profileForm.get('email').invalid">{{getEmailErrorMessage()}}</mat-error>
                        <!--<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
                        <!--<mat-hint>Hint</mat-hint>-->
                    </mat-form-field>

                    <div formGroupName="passwords">
                        <mat-form-field type="password" appearance="outline" class="input-formfield">
                            <mat-label>Password</mat-label>
                            <input matInput placeholder="Password" type="password" formControlName="password" required>
                            <mat-icon *ngIf="!editPassword" (click)="enableField('password')" class="include-pointer" matSuffix>edit</mat-icon>
                            <mat-error>
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="editPassword" type="password" appearance="outline" class="input-formfield">
                            <mat-label>Reenter Password</mat-label>
                            <input matInput placeholder="Password" type="password" formControlName="password2" required>
                            <mat-error>
                                This field is required
                            </mat-error>
                            <!--<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>-->
                            <!--<mat-hint>Hint</mat-hint>-->
                        </mat-form-field>
                        <!--https://stackoverflow.com/questions/46926182/property-controls-does-not-exist-on-type-abstractcontrol-angular-4-->
                        <mat-error *ngIf="profileForm.get('passwords').errors && profileForm.get('passwords')['controls'].password.touched && profileForm.get('passwords')['controls'].password2.touched"
                            class="error-margin-bottom">
                            {{getPasswordErrorMessage()}}
                        </mat-error>
                    </div>
                    <div *ngIf="haveInstitutions" class="drop-down-full-width">
                        <mat-form-field class="input-formfield">
                            <mat-label>Institution</mat-label>
                            <mat-select formControlName="institutionId">
                              <mat-option *ngFor="let institution of availableInstitutions" [value]="institution.id">{{institution.name}}</mat-option>
                            </mat-select>
                            <mat-icon *ngIf="!editInstitution" (click)="enableField('institution')" class="include-pointer" matSuffix>edit</mat-icon>
                        </mat-form-field>
                    </div>

                    <!--<mat-divider></mat-divider>-->
                    <!-- https://stackoverflow.com/questions/32062051/how-to-make-submit-button-disabled -->
                    <button mat-button type="submit"
                        [disabled]="profileForm.invalid && (profileForm.touched || profileForm.dirty)">Submit</button>
                    <!--https://github.com/redux-form/redux-form/issues/2679-->
                    <button mat-button type="button" (click)="onCancel()">Cancel</button>
                </form>
            </mat-card-content>
        </mat-card>

    </div>
</div>
