<!-- https://github.com/Robinyo/serendipity -->
<app-navigation-bar (toggleSidenav)="navigationSidenav.toggle()" (logUserOut)="logout()" [isLoggedIn]="isLoggedIn()"
  [currentUser]="currentUser">
</app-navigation-bar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #navigationSidenav class="sidenav" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <!--[opened]="(isHandset$ | async) === false"-->
    <mat-toolbar>
      <mat-toolbar-row>
        <h2 class="toolbar-title"> Particle Tracks </h2>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-nav-list>
      <!--[routerLink]="[item.route]"-->
      <a *ngIf="isLoggedIn()" mat-list-item (click)="navigationSidenav.close()" routerLinkActive="list-item-active"
        [routerLink]="['/events']">Choose/Analyze an Event</a>
      <a *ngIf="!isLoggedIn()" mat-list-item (click)="navigationSidenav.close()" routerLinkActive="list-item-active"
        [routerLink]="['/public/analyze-event']">Choose/Analyze an Event</a>
      <a *ngIf="isLoggedIn()" mat-list-item (click)="navigationSidenav.close()" routerLinkActive="list-item-active"
        [routerLink]="['/event-list']">
        Previously Analyzed Events
      </a>
      <a mat-list-item (click)="resourcesNavToggle()" routerLinkActive="list-item-active">
        <span matLine>Resources</span>
        <span>
          <mat-icon [@indicatorRotate]="resourcesNavExpanded ? 'expanded': 'collapsed'">
            expand_more
          </mat-icon>
        </span>
      </a>
      <div *ngIf="resourcesNavExpanded">
        <a mat-list-item (click)="navigationSidenav.close()"
          routerLinkActive="list-item-active" [routerLink]="['/public/background']">
          <span class="child-list-item">Background</span>
        </a>
        <a mat-list-item (click)="navigationSidenav.close()"
          routerLinkActive="list-item-active" [routerLink]="['/public/help-online-analysis']">
          <span class="child-list-item">Analysis -- Part I (Online)</span>
        </a>
        <a mat-list-item (click)="navigationSidenav.close()"
          routerLinkActive="list-item-active" [routerLink]="['/public/help-offline-analysis']">
          <span class="child-list-item">Analysis -- Part II (Offline)</span>
        </a>
        <a mat-list-item (click)="navigationSidenav.close()"
          routerLinkActive="list-item-active" [routerLink]="['/public/particle-decays']">
          <span class="child-list-item">List of Particle Decays</span>
        </a>
        <a mat-list-item (click)="navigationSidenav.close()"
          routerLinkActive="list-item-active" [routerLink]="['/public/particle-properties']">
          <span class="child-list-item">List of Particle Properties</span>
        </a>
        <!--
        <a mat-list-item (click)="navigationSidenav.close()"
          routerLinkActive="list-item-active" [routerLink]="['/public/particle-decays']">
          <span class="child-list-item">Explore</span>
        </a>
      -->
      </div>
      <div *ngIf="isAdmin()">
        <!-- https://stackblitz.com/edit/dynamic-nested-sidenav-menu?file=app%2Fapp.component.scss -->
        <a mat-list-item (click)="teacherNavToggle()" routerLinkActive="list-item-active">
          <!-- https://stackblitz.com/edit/mat-nav-list-with-buttons?file=app%2Flistview%2Flistview.component.html -->
          <span matLine>Teacher</span>
          <span>
            <mat-icon [@indicatorRotate]="teacherNavExpanded ? 'expanded': 'collapsed'">
              expand_more
            </mat-icon>
          </span>
        </a>
        <div *ngIf="teacherNavExpanded">
          <a mat-list-item (click)="teacherNavToggle()" (click)="navigationSidenav.close()"
            routerLinkActive="list-item-active" [routerLink]="['/teacher']">
            <span class="child-list-item">Dashboard</span>
          </a>
          <a mat-list-item (click)="teacherNavToggle()" (click)="navigationSidenav.close()"
            routerLinkActive="list-item-active">
            <span class="child-list-item">Resources</span>
          </a>
        </div>
      </div>

    </mat-nav-list>
  </mat-sidenav>

  <!--  
    Possibly good resource for a more elaborate side nav: https://stackblitz.com/edit/dynamic-nested-sidenav-menu?file=app%2Fmenu-list-item%2Fmenu-list-item.component.html
  -->


  <mat-sidenav-content role="main">
    <router-outlet></router-outlet>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>