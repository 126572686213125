<div [ngClass]="{'extra-left-right-padding': isPublicUser}">
    <mat-card-header>
        <mat-card-title *ngIf="!isPublicUser" class="card-title-container">
            <span *ngIf="event !== null; else no_event_container">
                <span [innerHTML]="event.name">
                </span>
            </span>
            <ng-template #no_event_container>
                <span>
                    No process selected yet
                </span>
            </ng-template>
        </mat-card-title>
        <mat-card-title *ngIf="isPublicUser">
            Review
        </mat-card-title>
        <mat-card-subtitle *ngIf="event !== null">
            A quick review of your data analysis for this event.
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <div *ngIf="event !== null">
            <div fxLayout="row" fxLayoutAlign="space-around center"
                *ngFor="let correctFeatureMessage of correctFeatureMessages" class="card-top-space">
                <div fxFlex.gt-sm="650px">
                    <mat-card class="all-is-well-card">
                        <mat-card-content>
                            <mat-icon class="align-icon">check_circle_outline</mat-icon>&nbsp;{{correctFeatureMessage}}
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-around center" *ngFor="let errorMessage of errorMessages"
                class="card-top-space">
                <div fxFlex.gt-sm="650px">
                    <mat-card class="warning-card">
                        <mat-card-content>
                            <mat-icon class="align-icon">warning</mat-icon>&nbsp;{{errorMessage}}
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngFor="let warningMessage of warningMessages"
                class="card-top-space">
                <div fxFlex.gt-sm="650px">
                    <mat-card class="mild-warning-card">
                        <mat-card-content *ngFor="let message of warningMessage.messages; let i = index">
                            <span *ngIf="i===0">
                                <mat-icon class="align-icon">warning</mat-icon>&nbsp;{{message}}
                            </span>
                            <span *ngIf="i>0">
                                {{message}}
                            </span>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div *ngIf="canSubmit && (warningMessages.length === 0) && (errorMessages.length === 0)" fxLayout="row"
                fxLayoutAlign="space-around center" class="card-top-space">
                <div fxFlex.gt-sm="650px">
                    <mat-card class="ready-to-submit-card">
                        <mat-card-content *ngIf="!isPublicUser">
                            <mat-icon class="align-icon">send</mat-icon>&nbsp;
                            You can submit your event if you are happy with the analysis!
                        </mat-card-content>
                        <mat-card-content *ngIf="isPublicUser">
                            <mat-icon class="align-icon">send</mat-icon>&nbsp;
                            If you were logged in right now, your event would be ready for submission!
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>




    </mat-card-content>
</div>